<template>
  <v-card class="ma-4">
    <v-toolbar>
      <v-toolbar-title>Chapel Students{{ chapelText }}</v-toolbar-title>
      <v-spacer />
      <v-btn text @click="showFilters = !showFilters">
        <v-icon left>{{ hasFilters ? 'fas fa-filter' : 'fal fa-filter' }}</v-icon>
        {{ showFilters ? 'Hide' : 'Show' }} Filters
      </v-btn>
      <!-- <v-dialog v-model="showFilters" width="500">
        <template v-slot:activator="{ on: dialogOn }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on: tooltipOn }">
              <v-btn text v-on="{ ...dialogOn, ...tooltipOn }">
                <v-icon left>{{ hasFilters ? 'fas fa-filter' : 'fal fa-filter' }}</v-icon>
                Filters
              </v-btn>
            </template>
            <span>{{ showFilters ? 'Hide' : 'Show' }} Filters</span>
          </v-tooltip>
        </template>
        <v-card>
          <v-toolbar class="mb-4">
            <v-toolbar-title>Student Filters</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click="showFilters = false">
              <v-icon>fal fa-times</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text>
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field v-model="nameFilter" label="Name" outlined hide-details></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-autocomplete v-model="hallFilter" :items="hallOptions" label="Hall" multiple outlined hide-details>
                  <template v-slot:selection="{ item, index }">
                    <v-chip v-if="hallFilter.length === 1" small>{{ item.text }}</v-chip>
                    <span v-else-if="hallFilter.length > 1 && index === 0" class="grey--text text-caption">{{ hallFilter.length }} Selected</span>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="12" md="6">
                <v-select v-model="classFilter" :items="classOptions" label="Class Level" multiple outlined hide-details>
                  <template v-slot:selection="{ item, index }">
                    <v-chip v-if="classFilter.length === 1" small>{{ item.text }}</v-chip>
                    <span v-else-if="classFilter.length > 1 && index === 0" class="grey--text text-caption">{{ classFilter.length }} Selected</span>
                  </template>
                </v-select>
              </v-col>
              <v-col cols="12" md="6">
                <v-autocomplete v-model="sportFilter" :items="sportOptions" label="Sports" multiple outlined hide-details>
                  <template v-slot:selection="{ item, index }">
                    <v-chip v-if="sportFilter.length === 1" small>{{ item.text }}</v-chip>
                    <span v-else-if="sportFilter.length > 1 && index === 0" class="grey--text text-caption">{{ sportFilter.length }} Selected</span>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="12" md="6">
                <v-select v-model="genderFilter" :items="genderOptions" label="Gender" outlined hide-details></v-select>
              </v-col>
              <v-col cols="12" md="6">
                <v-select v-model="fullPartTimeFilter" :items="fullPartTimeOptions" label="Full/Part Time" outlined hide-details></v-select>
              </v-col>
              <v-col v-if="chapelText === ''" cols="12" md="6">
                <v-text-field v-model="remainingMinFilter" :hint="'Chapels Remaining: ' + remainingChapels" label="Remaining Min" type="number" persistent-hint outlined></v-text-field>
              </v-col>
              <v-col v-if="chapelText === ''" cols="12" md="6">
                <v-text-field v-model="remainingMaxFilter" :hint="'Chapels Remaining: ' + remainingChapels" label="Remaining Max" type="number" persistent-hint outlined></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-btn @click="showFilters = false">Close</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog> -->
      <v-menu v-if="chapelText === ''" offset-y>
        <template v-slot:activator="{ on }">
          <v-btn text v-on="on">
            {{ termText }}
            <v-icon right>fal fa-chevron-down</v-icon>
          </v-btn>
        </template>
        <v-list style="max-height:300px;overflow:auto">
          <v-list-item v-for="item in termOptions" :key="item.value" @click="term = item.value">
            <v-list-item-title>{{ item.text }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <requirement-setup :term="term" @updated="loadStudents()"></requirement-setup>
      <grace-credit :term="term" :students="students"></grace-credit>
      <v-menu>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" icon>
            <v-icon>fal fa-ellipsis-v</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item @click="exportToExcel()">
            <v-list-item-avatar>
              <v-icon>fal fa-file-excel</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>Export Current List</v-list-item-title>
              <v-list-item-subtitle>Exports the current list to Excel</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="sendEmail">
            <v-list-item-avatar>
              <v-icon>fal fa-envelope</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>Email Current List</v-list-item-title>
              <v-list-item-subtitle>Send an email to the students in the current list</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
      <email ref="emailDialog" :data="emailStudents" :merge-fields="emailMergeFields" from="report-email@covenant.edu"></email>
    </v-toolbar>
    <v-card-text v-if="showFilters">
      <v-row>
        <v-col :cols="6" :md="3">
          <v-text-field v-model="nameFilter" label="Name" outlined hide-details dense></v-text-field>
        </v-col>
        <v-col :cols="6" :md="3">
          <v-autocomplete v-model="hallFilter" :items="hallOptions" label="Hall" multiple outlined hide-details dense>
            <template v-slot:selection="{ item, index }">
              <v-chip v-if="hallFilter.length === 1" small>{{ item.text }}</v-chip>
              <span v-else-if="hallFilter.length > 1 && index === 0" class="grey--text text-caption">{{ hallFilter.length }} Selected</span>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col :cols="6" :md="3">
          <v-select v-model="classFilter" :items="classOptions" label="Class Level" multiple outlined hide-details dense>
            <template v-slot:selection="{ item, index }">
              <v-chip v-if="classFilter.length === 1" small>{{ item.text }}</v-chip>
              <span v-else-if="classFilter.length > 1 && index === 0" class="grey--text text-caption">{{ classFilter.length }} Selected</span>
            </template>
          </v-select>
        </v-col>
        <v-col :cols="6" :md="3">
          <v-autocomplete v-model="sportFilter" :items="sportOptions" label="Sports" multiple outlined hide-details dense>
            <template v-slot:selection="{ item, index }">
              <v-chip v-if="sportFilter.length === 1" small>{{ item.text }}</v-chip>
              <span v-else-if="sportFilter.length > 1 && index === 0" class="grey--text text-caption">{{ sportFilter.length }} Selected</span>
            </template>
          </v-autocomplete>
        </v-col>
      </v-row>
      <v-row>
        <v-col :cols="6" :md="3">
          <v-select v-model="genderFilter" :items="genderOptions" label="Gender" outlined hide-details dense></v-select>
        </v-col>
        <v-col :cols="6" :md="3">
          <v-select v-model="fullPartTimeFilter" :items="fullPartTimeOptions" label="Full/Part Time" outlined hide-details dense></v-select>
        </v-col>
        <v-col v-if="chapelText === ''"  :cols="6" :md="3">
          <v-row>
            <v-col>
              <v-text-field v-model="remainingMinFilter" :hint="'Chapels Remaining: ' + remainingChapels" label="Min Remaining" type="number" persistent-hint outlined dense></v-text-field>
            </v-col>
            <v-col>
              <v-text-field v-model="remainingMaxFilter" :hint="'Chapels Remaining: ' + remainingChapels" label="Max Remaining" type="number" persistent-hint outlined dense></v-text-field>
            </v-col>
          </v-row>
        </v-col>
        <v-col :cols="6" :md="3">
          <v-switch v-model="showPhotos" label="Show Photos" class="mt-1" />
        </v-col>
      </v-row>
    </v-card-text>
    <v-data-table :items="students" :headers="headers" :server-items-length="studentCount" :options.sync="tableOptions" :footer-props="{ 'items-per-page-options': [5, 10, 15, 20, 30, 40, 50] }" @dblclick:row="(e, { item }) => viewStudent(item)">
      <template v-slot:item.photo="{ item }">
        <v-img :src="item.photo || '/img/no.jpg'" height="80" width="60" />
      </template>
      <template v-slot:item.actions="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on" :to="'/chapel/student/' + item.bannerId + '/' + term">
              <v-icon>fal fa-eye</v-icon>
            </v-btn>
          </template>
          <span>View/Edit Credits Received</span>
        </v-tooltip>
      </template>
      <template v-slot:item.date="{ item }">{{ stringFormatDate(item.date) }}</template>
      <template v-slot:no-data>
        <v-alert v-if="isLoading" type="info">Loading students. This should only take a few seconds...</v-alert>
        <span v-else>There are no students matching your current filter/search or there was an error loading.</span>
      </template>
    </v-data-table>
  </v-card>
</template>
<script>
import { ref, computed, watch, onMounted } from '@vue/composition-api'
import { sports } from '@/components/chapel/functions'
import { stringFormatDate } from '@/helpers/formatters'
import { saveAs } from 'file-saver'

export default {
  components: {
    RequirementSetup: () => import('@/components/chapel/requirementSetup'),
    GraceCredit: () => import('@/components/chapel/graceCredit'),
    Email: () => import('@/components/system/emailDialog')
  },
  setup (props, { root }) {
    const chapelId = computed(() => root.$route.params.id || null)
    const creditType = computed(() => root.$route.params.type || null)
    const chapelText = ref('')
    const term = computed({
      get: () => root.$store.state.chapel.studentTerm,
      set: (term) => {
        root.$store.commit('chapel/setStudentTerm', term)
      }
    })
    const termOptions = computed(() => root.$store.state.chapel.studentTermOptions)
    const termText = computed(() => (term.value === '' ? 'Select Term' : (term.value.substring(4) === '05' ? 'Spring ' : 'Fall ') + term.value.substring(0, 4)))
    const termSearchActive = ref(false)
    const termSearchRef = ref(null)

    function openTermSearch () {
      termSearchActive.value = true
      setTimeout(() => { termSearchRef.value.focus() }, 100)
    }

    onMounted(async () => {
      if (root.$store.state.chapel.studentTermOptions.length === 0) {
        await root.$store.dispatch('chapel/loadOptions')
        if (termOptions.value.length > 0) {
          console.log(termOptions.value[0])
          root.$store.commit('chapel/setStudentTerm', termOptions.value[0].value)
        } else {
          console.log('termOptions is empty')
        }
      }
    })

    const remainingChapels = ref(0)

    watch([term, termOptions], () => {
      if (term.value !== '' && termOptions.value.length > 0) {
        for (let i = 0; i < termOptions.value.length; i++) {
          if (termOptions.value[i].code === term.value) termText.value = termOptions.value[i].text
        }
        root.$feathers.service('chapel/schedule').find({ query: { term: term.value, date: { $gt: new Date() }, $limit: 0 } }).then(({ total }) => {
          remainingChapels.value = total
        })
      }
    })

    const students = ref([])
    const studentCount = ref(0)
    const showPhotos = ref(true)
    const headers = computed(() => {
      const arr = [
        { text: 'Banner ID', value: 'bannerId', exportWidth: 11 },
        { text: 'Name', value: 'name', exportWidth: 20 },
        { text: 'Hall', value: 'hall', exportWidth: 20 },
        { text: 'Class', value: 'classLevel', exportWidth: 5 }
      ]
      if (chapelId.value) {
        if (creditType.value == null) {
          arr.push({ text: 'Credit Type', value: 'type', exportWidth: 10 })
        }
        arr.push({ text: 'Reason', value: 'reason', exportWidth: 30 })
        arr.push({ text: 'Date', value: 'date', exportWidth: 13 })
      } else {
        arr.push({ text: 'Required', value: 'required', exportWidth: 10 })
        arr.push({ text: 'Received', value: 'received', exportWidth: 10 })
        arr.push({ text: 'Remaining', value: 'remaining', exportWidth: 10 })
        arr.push({ text: '', value: 'actions' })
      }
      if (showPhotos.value) arr.splice(0, 0, { text: 'Photo', value: 'photo', sortable: false })
      return arr
    })
    const emailMergeFields = computed(() => {
      const arr = headers.value.filter(({ text }) => text !== '' && text !== 'Photo').map(({ text, value }) => { return { label: text, value } })
      arr.splice(1, 1, { label: 'First', value: 'first' })
      arr.splice(2, 0, { label: 'Last', value: 'last' })
      return arr
    })
    const tableOptions = computed({
      get: () => root.$store.state.chapel.studentResultOptions,
      set: (val) => root.$store.commit('chapel/setStudentResultOptions', val)
    })

    const showFilters = ref(false)
    const nameFilter = computed({
      get: () => root.$store.state.chapel.studentFilter.name,
      set: (val) => root.$store.commit('chapel/setStudentFilterField', { field: 'name', filter: val })
    })
    const hallFilter = computed({
      get: () => root.$store.state.chapel.studentFilter.hall,
      set: (val) => root.$store.commit('chapel/setStudentFilterField', { field: 'hall', filter: val })
    })
    const classFilter = computed({
      get: () => root.$store.state.chapel.studentFilter.classLevel,
      set: (val) => root.$store.commit('chapel/setStudentFilterField', { field: 'classLevel', filter: val })
    })
    const sportFilter = computed({
      get: () => root.$store.state.chapel.studentFilter.sport,
      set: (val) => root.$store.commit('chapel/setStudentFilterField', { field: 'sport', filter: val })
    })
    const genderFilter = computed({
      get: () => root.$store.state.chapel.studentFilter.gender,
      set: (val) => root.$store.commit('chapel/setStudentFilterField', { field: 'gender', filter: val })
    })
    const remainingMinFilter = computed({
      get: () => root.$store.state.chapel.studentFilter.remainingMin,
      set: (val) => root.$store.commit('chapel/setStudentFilterField', { field: 'remainingMin', filter: val })
    })
    const remainingMaxFilter = computed({
      get: () => root.$store.state.chapel.studentFilter.remainingMax,
      set: (val) => root.$store.commit('chapel/setStudentFilterField', { field: 'remainingMax', filter: val })
    })
    const fullPartTimeFilter = computed({
      get: () => root.$store.state.chapel.studentFilter.fullPartTime,
      set: (val) => root.$store.commit('chapel/setStudentFilterField', { field: 'fullPartTime', filter: val })
    })
    const hasFilters = computed(() => {
      for (const field in root.$store.state.chapel.studentFilter) {
        if (typeof (root.$store.state.chapel.studentFilter[field]) === 'object') {
          if (root.$store.state.chapel.studentFilter[field].length > 0) return true
        } else if (root.$store.state.chapel.studentFilter[field] !== '') {
          return true
        }
      }
    })
    const fullPartTimeOptions = ref([{ text: 'All', value: '' }, { text: 'Full Time', value: 'F' }, { text: 'Part Time', value: 'P' }])
    const hallOptions = computed(() => root.$store.state.chapel.hallOptions)
    const classOptions = ref([
      { text: 'Freshmen', value: 'FR' },
      { text: 'Sophomore', value: 'SO' },
      { text: 'Junior', value: 'JR' },
      { text: 'Senior', value: 'SR' }
    ])
    const sportOptions = ref(sports)
    const genderOptions = ref([
      { text: 'All', value: '' },
      { text: 'Female', value: 'F' },
      { text: 'Male', value: 'M' }
    ])
    const isLoading = ref(true)
    watch([term, tableOptions, nameFilter, hallFilter, classFilter, sportFilter, genderFilter, remainingMinFilter, remainingMaxFilter, fullPartTimeFilter], loadStudents)
    watch(chapelId, async () => {
      if (chapelId.value != null) {
        try {
          const data = await root.$feathers.service('chapel/schedule').get(chapelId.value)
          let str = stringFormatDate(data.date, true)
          if (str.substring(21, 26) === '11:00') str = str.substring(0, 17)
          if (creditType.value) str += ' - ' + creditType.value + ' Credits'
          chapelText.value = ' - ' + str
          term.value = data.term
        } catch (e) {
          root.$store.dispatch('main/snackbar', { type: 'error', text: 'Error looking up chapel: ' + e })
        }
        await loadStudents()
      }
    })
    function buildAggregate ({ isExport, includePagination, includeSort, doCount }) {
      const aggregate = []
      if (chapelId.value != null && chapelId.value !== '') {
        const $match = {}
        if (creditType.value != null && creditType.value !== '') {
          $match.credits = { $elemMatch: { chapel: chapelId.value, type: creditType.value } }
        } else {
          $match['credits.chapel'] = chapelId.value
        }
        aggregate.push({ $match })
        aggregate.push({ $unwind: '$credits' })
        if (creditType.value != null && creditType.value !== '') {
          aggregate.push({ $match: { 'credits.chapel': chapelId.value, 'credits.type': creditType.value } })
        } else {
          aggregate.push({ $match: { 'credits.chapel': chapelId.value } })
        }
        aggregate.push({ $lookup: { from: 'Directory', localField: 'bannerId', foreignField: 'bannerId', as: 'directory' } })
        aggregate.push({
          $project: {
            bannerId: 1,
            name: { $concat: ['$name.last', ', ', '$name.preferred'] },
            last: '$name.last',
            first: '$name.preferred',
            gender: '$bio.gender',
            hall: '$housing.hall',
            classLevel: 1,
            fullPartTime: 1,
            sports: 1,
            type: '$credits.type',
            reason: '$credits.reason',
            date: '$credits.date',
            directory: { $first: '$directory' }
          }
        })
        aggregate.push({
          $project: {
            bannerId: 1,
            name: 1,
            last: 1,
            first: 1,
            email: '$directory.email',
            directoryId: '$directory._id',
            gender: 1,
            hall: 1,
            classLevel: 1,
            fullPartTime: 1,
            sports: 1,
            type: 1,
            reason: 1,
            date: 1
          }
        })
      } else {
        aggregate.push({ $match: { term: term.value } })
        aggregate.push({ $lookup: { from: 'Directory', localField: 'bannerId', foreignField: 'bannerId', as: 'directory' } })
        aggregate.push({
          $project: {
            bannerId: 1,
            name: { $concat: ['$name.last', ', ', '$name.preferred'] },
            last: '$name.last',
            first: '$name.preferred',
            gender: '$bio.gender',
            hall: '$housing.hall',
            classLevel: 1,
            fullPartTime: 1,
            sports: 1,
            directory: { $first: '$directory' },
            required: '$required',
            received: { $reduce: { input: '$credits', initialValue: 0, 'in': { $sum: '$credits.credit' } } }
          }
        })
        aggregate.push({
          $project: {
            bannerId: 1,
            name: 1,
            last: 1,
            first: 1,
            email: '$directory.email',
            directoryId: '$directory._id',
            gender: 1,
            hall: 1,
            classLevel: 1,
            fullPartTime: 1,
            sports: 1,
            required: 1,
            received: 1,
            remaining: { $max: [ 0, { $subtract: ['$required', '$received'] } ] }
          }
        })
      }
      if (aggregate.length > 0) {
        if (showPhotos.value && !isExport) {
          aggregate[aggregate.length - 1].$project.photo = '$directory.photo'
        }
        aggregate.push(buildFilterAggregate())
        const { page, itemsPerPage, sortBy, sortDesc } = tableOptions.value
        if (includeSort) {
          const $sort = {}
          if (sortBy.length > 0) {
            for (let i = 0; i < sortBy.length; i++) {
              $sort[sortBy[i]] = sortDesc[i] ? -1 : 1
            }
          } else {
            $sort.name = 1
          }
          aggregate.push({ $sort })
        }
        if (includePagination) {
          aggregate.push({ $skip: (page - 1) * itemsPerPage })
          aggregate.push({ $limit: itemsPerPage })
        } else if (doCount) {
          aggregate.push({ $count: 'count' })
        }
      }
      return aggregate
    }
    function buildFilterAggregate () {
      const $match = {}
      if (nameFilter.value != null && nameFilter.value.trim() !== '') {
        let text = nameFilter.value.trim()
        if (text.search(', ') >= 0) {
          text = text.replace(', ', '[a-z]*, [a-z]*')
        } else if (text.search(',') >= 0) {
          text = text.replace(',', '[a-z]*, [a-z]*')
        } else if (text.search(' ') >= 0) {
          const temp = text.split(' ')
          text = temp[1] + '[a-z]*,[ ]*[a-z]*' + temp[0]
        }
        $match.name = { $regex: text, $options: 'i' }
      }
      if (hallFilter.value.length > 0) {
        $match.hall = { $in: hallFilter.value }
      }
      if (classFilter.value.length > 0) {
        $match.classLevel = { $in: classFilter.value }
      }
      if (sportFilter.value.length > 0) {
        $match.sports = { $in: sportFilter.value }
      }
      if (genderFilter.value !== '') {
        $match.gender = genderFilter.value
      }
      if (remainingMinFilter.value !== '') {
        const val = parseInt(remainingMinFilter.value)
        if (!isNaN(val)) {
          $match.remaining = { $gte: val }
        }
      }
      if (remainingMaxFilter.value !== '') {
        const val = parseInt(remainingMaxFilter.value)
        if (!isNaN(val)) {
          if (!('remaining' in $match)) $match.remaining = {}
          $match.remaining.$lte = val
        }
      }
      if (fullPartTimeFilter.value !== '') {
        $match.fullPartTime = fullPartTimeFilter.value
      }
      return { $match }
    }

    async function loadStudents () {
      isLoading.value = true
      const countAggregate = buildAggregate({ doCount: true })
      const { data: cnt } = await root.$feathers.service('chapel/student').find({ query: { aggregate: countAggregate } })
      if (cnt.length > 0) {
        studentCount.value = cnt[0].count
        const aggregate = buildAggregate({ includePagination: true, includeSort: true })
        const { data } = await root.$feathers.service('chapel/student').find({ query: { aggregate } })
        students.value = data
      } else {
        studentCount.value = 0
        students.value = []
      }
      isLoading.value = false
    }

    function changeRequirements (item) {}
    function viewSchedule (item) {}

    function viewStudent (item) {
      console.log(item)
      console.log(term.value)
      root.$router.push('/chapel/student/' + item.bannerId + '/' + term.value)
    }

    async function exportToExcel () {
      const arr = [ ...headers.value.filter(({ exportWidth }) => exportWidth != null) ]
      arr.splice(4, 0, { text: 'Sports', value: 'sports', exportWidth: 20 })
      arr.splice(2, 0, { text: 'Email Address', value: 'email', exportWidth: 30 })
      const header = arr.filter((text) => text !== '' && text !== 'Photo').map(({ text: header, value: key, exportWidth: width }) => { return { header, align: (header === 'Required' || header === 'Received' || header === 'Remaining' ? 'right' : 'left'), key, width } })
      const aggregate = buildAggregate({ isExport: true, includeSort: true })
      const { data } = await root.$feathers.service('chapel/student').find({ query: { aggregate } })
      let filename = 'Chapel Student Export.xlsx'
      root.$feathers.service('export/xlsx').create({ filename, header, rows: data.map((row) => { return { ...row, sports: row.sports ? row.sports.join('; ') : '' } }) }).then((data) => {
        saveAs(new Blob([data.buffer]), filename)
      })
    }

    const emailDialog = ref(null)
    const emailStudents = ref([])
    async function sendEmail () {
      const aggregate = buildAggregate({ isExport: true })
      const { data } = await root.$feathers.service('chapel/student').find({ query: { aggregate } })
      emailStudents.value = data.map((row) => { return { ...row, sports: row.sports ? row.sports.join('; ') : '' } })
      emailDialog.value.active = true
    }

    return {
      chapelId,
      creditType,
      chapelText,
      term,
      termOptions,
      termText,
      termSearchActive,
      termSearchRef,
      openTermSearch,
      remainingChapels,
      students,
      studentCount,
      showPhotos,
      headers,
      emailMergeFields,
      tableOptions,
      isLoading,
      loadStudents,
      showFilters,
      nameFilter,
      hallFilter,
      classFilter,
      sportFilter,
      genderFilter,
      remainingMinFilter,
      remainingMaxFilter,
      fullPartTimeFilter,
      hasFilters,
      fullPartTimeOptions,
      hallOptions,
      classOptions,
      sportOptions,
      genderOptions,
      changeRequirements,
      viewSchedule,
      viewStudent,
      emailDialog,
      emailStudents,
      sendEmail,
      stringFormatDate,
      exportToExcel
    }
  }
}
</script>
